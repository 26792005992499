import cn from 'classnames';
import styles from './Accordion.module.scss';
import { MenuItem } from '@/types';
import { ReactNode, useRef, useState } from 'react';
import { ArrowLinkIcon } from '../Icons';
import { useRouter } from 'next/router';
import { BACKEND_URL } from '@/constants';
import { useDispatch } from 'react-redux';
import { setShowSidebar } from '@/store';
import { Divider } from '../Divider';

type AccordionProps = {
  title: string;
  className?: string;
  items?: MenuItem[];
  content?: ReactNode;
  icon?: string;
  link?: string;
};

export function Accordion({
  title,
  content,
  className,
  items,
  icon,
  link,
}: AccordionProps) {
  const accordionClassName = cn(styles.accordion, className);
  const [open, setOpen] = useState(false);
  const ContentRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const defaultMaxHeight = open ? ContentRef.current?.scrollHeight : '0px';
  const [currentMaxHeight, setCurrentMaxHeight] = useState(defaultMaxHeight);
  const dispatch = useDispatch();

  const toggleOpening = () => {
    if ((!items || items.length === 0) && link) {
      dispatch(setShowSidebar(false));
      router.push(link);
    }
    setOpen(!open);
    const content = ContentRef.current;
    if (content) {
      if (currentMaxHeight === '0px') {
        setCurrentMaxHeight(content.scrollHeight + 20 + 'px');
      } else {
        setCurrentMaxHeight('0px');
      }
    }
  };

  const listClassName = cn(styles.list, {
    [styles.hidden]: !open,
  });

  return (
    <div className={accordionClassName}>
      <div onClick={toggleOpening} className={styles.title}>
        {title}
        {icon && (
          <img
            loading="lazy"
            src={`${BACKEND_URL}${icon}`}
            height={20}
            width={20}
            alt="nav-menu"
          />
        )}
        {((items && items.length > 0) || content) && (
          <div className={styles.icon}>
            <ArrowLinkIcon isOpen={open} />
          </div>
        )}
      </div>
      {items && items.length > 0 && (
        <div
          className={listClassName}
          ref={ContentRef}
          style={{ maxHeight: currentMaxHeight }}
        >
          {items &&
            items.length > 0 &&
            items.map((item) => (
              <div
                className={styles.title}
                key={item.id}
                onClick={() => {
                  dispatch(setShowSidebar(false));
                  router.push(item.value);
                }}
              >
                {item.name}
                <div className={styles.icon}>
                  <ArrowLinkIcon staticArrow />
                </div>
              </div>
            ))}
        </div>
      )}
      {content && (
        <div
          className={listClassName}
          ref={ContentRef}
          style={{ maxHeight: currentMaxHeight }}
        >
          {content}
        </div>
      )}
      <Divider className={styles.divider} />
    </div>
  );
}
