import cn from 'classnames';
import styles from './FlexColumn.module.scss';
import { ReactNode } from 'react';

type FlexColumnProps = {
  children: ReactNode;
  className?: string;
};

export function FlexColumn({ children, className }: FlexColumnProps) {
  const wrapperClassName = cn(styles.wrapper, className);

  return <div className={wrapperClassName}>{children}</div>;
}
