import cn from 'classnames';
import styles from './LocaleButton.module.scss';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setShowSidebar } from '@/store';

type LocaleButtonType = {
  className?: string;
};

export const LocaleButton = ({ className }: LocaleButtonType) => {
  const buttonClassName = cn(styles.button, className);
  const router = useRouter();
  const [active, setActive] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();

  const toggleActive = () => {
    setActive((prev) => !prev);
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const { target } = e;
      if (target instanceof Node && !buttonRef.current?.contains(target)) {
        setActive(false);
      }
    };

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [active]);

  const handleLocaleClick = (locale: string) => {
    if (locale.toLowerCase() === router.locale?.toLowerCase()) {
      return;
    }
    router.push(router.pathname, router.asPath, { locale });
    dispatch(setShowSidebar(false));
  };

  return (
    <div className={styles.wrapper}>
      <button
        className={buttonClassName}
        onClick={toggleActive}
        ref={buttonRef}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.svg}
        >
          <path
            d="M2 8.33333H5.51852M2 8.33333C2 10.013 2.66726 11.6239 3.85499 12.8117C5.04272 13.9994 6.65363 14.6667 8.33333 14.6667M2 8.33333C2 6.65363 2.66726 5.04272 3.85499 3.85499C5.04272 2.66726 6.65363 2 8.33333 2M5.51852 8.33333H11.1481M5.51852 8.33333C5.51852 11.8307 6.77815 14.6667 8.33333 14.6667M5.51852 8.33333C5.51852 4.83593 6.77815 2 8.33333 2M8.33333 14.6667C9.88852 14.6667 11.1481 11.8307 11.1481 8.33333M8.33333 14.6667C10.013 14.6667 11.6239 13.9994 12.8117 12.8117C13.9994 11.6239 14.6667 10.013 14.6667 8.33333M8.33333 2C9.88852 2 11.1481 4.83593 11.1481 8.33333M8.33333 2C10.013 2 11.6239 2.66726 12.8117 3.85499C13.9994 5.04272 14.6667 6.65363 14.6667 8.33333M11.1481 8.33333H14.6667"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span className={styles.locale}>{router.locale?.toUpperCase()}</span>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={cn(styles.svg, {
            [styles.active]: active,
          })}
        >
          <path
            d="M4 6L8 10L12 6"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {active && (
        <div className={styles.menu}>
          <div
            className={cn(styles.option, {
              [styles.optionActive]: 'ru' === router.locale?.toLowerCase(),
            })}
            onClick={() => handleLocaleClick('ru')}
          >
            <span className={styles.optionBold}>RU</span>
            <span className={styles.optionText}>Русский</span>
          </div>
          <div
            className={cn(styles.option, {
              [styles.optionActive]: 'en' === router.locale?.toLowerCase(),
            })}
            onClick={() => handleLocaleClick('en')}
          >
            <span className={styles.optionBold}>EN</span>
            <span className={styles.optionText}>English</span>
          </div>
        </div>
      )}
    </div>
  );
};
