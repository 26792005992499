import styles from './BuyTicket.module.scss';
import { useRouter } from 'next/router';
import { BuyButtonLink } from '@/types';
import { pixelRunScript } from '@/utils/pixelRunScript';
import { useAdaptiveWidth } from '@/hooks/useAdaptiveWidth';
import cn from 'classnames';

export function BuyTicket({
  links,
  text = 'Купить билет',
}: {
  links: BuyButtonLink[];
  text?: string;
}) {
  const { isTablet } = useAdaptiveWidth();
  const router = useRouter();
  const currentPath = router.asPath;
  const getButtonLink = (
    currentPath: string,
    links: BuyButtonLink[],
  ): string => {
    const link = links.find((link) => link.path === currentPath);
    return link ? link.buyPath : '/tickets';
  };
  const getButtonMetrics = (
    currentPath: string,
    links: BuyButtonLink[],
  ): string => {
    const link = links.find((link) => link.path === currentPath);
    return link ? link.vkId : 'buy-ticket';
  };

  const buttonLink = getButtonLink(currentPath, links);

  const buttonMetrics = getButtonMetrics(currentPath, links);

  const handleButtonClick = () => {
    pixelRunScript(buttonMetrics);
    router.push(buttonLink);
  };

  if (isTablet)
    return (
      <div className={styles.wrapper}>
        <div className={cn(styles.button, 'custom-buy-ticket-button')}>
          <img
            loading="lazy"
            src="/svg/ticket.svg"
            width={24}
            height={24}
            alt="buy-ticket"
            onClick={handleButtonClick}
          />
        </div>
      </div>
    );

  return (
    <div className={styles.container} onClick={handleButtonClick}>
      <img
        src="/svg/buy.svg"
        alt="buy-ticket"
        className={styles.buy}
        loading="lazy"
      />
      <span className={styles.text}>{text}</span>
    </div>
  );
}
